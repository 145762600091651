.active div {
  background: var(--chakra-colors-primary-500);
  color: var(--chakra-colors-white);
}

.faq-ul {
  counter-reset: faq-counter;
}

.faq-ul li a {
  color: blue;
}

.faq-ul li:not(.skip) {
  counter-increment: faq-counter;
  list-style: none;
}

.faq-ul li:not(.skip)::before {
  font-weight: bold;
  content: counter(faq-counter) '.';
}

.faq-ul .skip {
  list-style: none;
  text-indent: -2em;
}

.react-time-picker {
  width: 17%;
}
