/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.send_button {
  margin-right: 230px;
}

@media (max-width: 480px) {
  .send_button {
    margin-right: 80px;
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--chakra-colors-blue-400);
  color: var(--chakra-colors-blue-400);
  box-shadow: 9999px 0 0 -5px var(--chakra-colors-blue-400);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--chakra-colors-blue-400);
  color: var(--chakra-colors-blue-400);
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px var(--chakra-colors-blue-400);
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px var(--chakra-colors-blue-400);
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px var(--chakra-colors-blue-400);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--chakra-colors-blue-400);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--chakra-colors-blue-400);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--chakra-colors-blue-400);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--chakra-colors-blue-400);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--chakra-colors-blue-400);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px var(--chakra-colors-blue-400);
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--chakra-colors-blue-400);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--chakra-colors-blue-400);
  }
}
